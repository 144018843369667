<template>
  <div>
    <b-modal
      id="add-device-modal"
      ref="add-device-modal"
      size="lg"
      @hidden="cancel"
      :title="$t('modals.addDevice.text')"
    >
      <div class="row">
        <!-- Serial -->
        <div class="col-6 col-lg-6">
          <label for="serial">Serial</label>
          <b-form-input
            id="serial"
            v-model="deviceAdd.serial"
            class="mb-1"
            placeholder="Serial"
            :state="err.serial ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.serial }}
          </b-form-invalid-feedback>
        </div>
        <!-- Reference -->
        <div class="col-6 col-lg-6">
          <label for="reference">{{$t("modals.reference.text")}}</label>
          <b-form-input
            id="reference"
            v-model="deviceAdd.ref"
            class="mb-1"
            :placeholder="$t('modals.reference.text')"
            :state="err.ref ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.ref }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Name -->
        <div class="col-6 col-lg-6">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="deviceAdd.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
        <!-- Type -->
        <div class="col-6 col-lg-6">
          <label for="type">{{$t("modals.type.text")}}</label>
          <b-form-select
            id="type"
            :options="deviceTypesData"
            v-model="deviceAdd.type"
            class="mb-1"
            :state="err.type ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.type }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Marque -->
        <div class="col-6 col-lg-6">
          <label for="marque">Marque</label>
          <b-form-select
            id="marque"
            :options="marquesData"
            v-model="deviceAdd.marque"
            class="mb-1"
            :state="err.marque ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.marque }}
          </b-form-invalid-feedback>
        </div>
        <!-- Thermostat -->
        <div class="col-6 col-lg-6">
          <label for="thermostat">Thermostat</label>
          <b-form-select
            id="thermostat"
            :options="thermostatData"
            v-model="deviceAdd.thermostat"
            class="mb-1"
            :state="err.thermostat ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.thermostat }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Region -->
        <div class="col-6 col-lg-6">
          <label for="region">{{$t("modals.region.text")}}</label>
          <b-form-select
            id="region"
            v-model="region"
            :options="regionsData"
            class="mb-1"
            :state="err.region ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.region }}
          </b-form-invalid-feedback>
        </div>
        <!-- Province -->
        <div class="col-6 col-lg-6">
          <label for="province">{{$t("modals.province.text")}}</label>
          <b-form-select
            id="province"
            v-model="deviceAdd.province"
            :options="provincesData"
            class="mb-1"
            :state="err.province ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.province }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row">
        <!-- Stock Type -->
        <div class="col-12 col-lg-12">
          <label for="stockType">{{$t("modals.stockType.text")}}</label>
          <b-form-select
            id="stockType"
            v-model="deviceAdd.stockType"
            :options="stockTypesData"
            class="mb-1"
            :state="err.stockType ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.stockType }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Vaccintypes -->
      <div class="row">
        <div class="col-12 col-lg-12" data-app>
          <label for="vaccintypes">{{ $t("modals.vaccinType.text") }}</label>
          <v-select
            id="vaccintypes"
            v-model="vaccintypesSelect"
            :items="vaccintypesFilterData"
            :menu-props="{ maxHeight: '200' }"
            item-text="text"
            item-value="value"
            multiple
          ></v-select>
        </div>
      </div>
      <!-- Phones -->
      <div class="row">
        <div class="col-12 col-lg-12" data-app>
          <label for="phones">{{ $t("modals.contacts.text") }}</label>
          <b-form-tags
            input-id="phones"
            v-model="deviceAdd.phones"
            :placeholder="$t('modals.addContact.text')"
            :add-button-text="$t('buttons.add.text')"
            remove-on-delete
          ></b-form-tags>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{$t("buttons.add.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  provincesMethods,
  provincesComputed,
} from "@/state/helpers";
export default {
  props: ["regions", "provinces", "vaccintypes", "marques"],
  created() {},
  computed: {
    ...provincesComputed,
  },
  data() {
    return {
      regionsData: [{ value: null, text: this.$t("titles.regions.text"), disabled: true }],
      provincesData: [{ value: null, text: this.$t("titles.provinces.text"), disabled: true }],
      deviceTypesData: [
        { value: null, text: this.$t("titles.deviceTypes.text"), disabled: true },
        { value: 1, text: "REFRIGERATEUR" },
        { value: 2, text: "CONGELATEUR" },
        { value: 3, text: "ARMOIRES FRIGORIFIQUE" },
        { value: 4, text: "CHAMBRE FROIDE" },
        { value: 5, text: "CAMION" },
      ],
      marquesData: [{ value: null, text: this.$t("titles.marques.text"), disabled: true }],/* [
        { value: null, text: "Marques", disabled: true },
        { value: "SIERA", text: "SIERA" },
        { value: "OJ COMPANY", text: "OJ COMPANY" },
        { value: "LIEBHERR", text: "LIEBHERR" },
        { value: "SIAFMO", text: "SIAFMO" },
        { value: "BEKO", text: "BEKO" },
        { value: "ARISTON", text: "ARISTON" },
        { value: "HOTPOINT", text: "HOTPOINT" },
        { value: "MASTERKIT", text: "MASTERKIT" },
        { value: "BRANDT", text: "BRANDT" },
        { value: "FITCO", text: "FITCO" },
        { value: "LG", text: "LG" },
        { value: "FAGOR", text: "FAGOR" },
        { value: "WHIRLPOOL", text: "WHIRLPOOL" },
        { value: "SAMSUNG", text: "SAMSUNG" },
        { value: "BOSCH", text: "BOSCH" },
        { value: "PASSAP", text: "PASSAP" },
        { value: "BIOBASE", text: "BIOBASE" },
        { value: "DAIKO", text: "DAIKO" },
        { value: "HAIER", text: "HAIER" },
        { value: "POLERNO", text: "POLERNO" },
        { value: "CORECO", text: "CORECO" },
        { value: "NC", text: "NC" }
      ], */
      thermostatData: [
        { value: null, text: "Thermostat", disabled: true },
        { value: "Digital", text: "Digital" },
        { value: "Manuel", text: "Manuel" }
      ],
      stockTypesData: [
        { value: null, text: this.$t("titles.stockTypes.text"), disabled: true },
        { value: "24 H", text: "24 H" },
        { value: "VIDE", text: "VIDE" },
      ],
      vaccintypesFilterData: [{ value: null, text: "--All--" }],
      vaccintypesSelect: [null],
      vaccintypesData: [],
      region: null,
      deviceAdd: {
        serial: null,
        ref: null,
        name: null,
        marque: null,
        thermostat: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
        vaccinTypes: null,
        phones: null,
      },
      err: {
        serial: null,
        ref: null,
        name: null,
        marque: null,
        thermostat: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
        vaccinTypes: null,
      },
    };
  },
  watch: {
    region(newVal){
      Object.assign(this.deviceAdd, {region: newVal, province: null});
      this.retrieveProvinces(newVal)
    },
    regions(newVal) {
      let regionsList = newVal;
      regionsList.map((obj) => {
          this.regionsData.push({ value: obj.id, text: obj.name });
      });
    },
    marques(newVal) {
      let marquesList = newVal;
      marquesList.map((obj) => {
          this.marquesData.push({ value: obj.id, text: obj.name });
      });
    },
    vaccintypesSelect(newVal) {
      let lenVaccintypes = newVal.length;

      if (!newVal[lenVaccintypes - 1] && lenVaccintypes > 1) {
        this.vaccintypesSelect = [null];
        let vaccins = [];
        this.vaccintypes.forEach(vaccin=>{
          vaccins.push(vaccin.id);
        })
        this.deviceAdd.vaccinTypes = vaccins;
      } else if (newVal[lenVaccintypes - 1] && newVal.includes(null)) {
        var filteredVaccintypes = this.vaccintypesSelect.filter(function (
          item
        ) {
          return item != null;
        });
        this.vaccintypesSelect = filteredVaccintypes;
      } else if (newVal[lenVaccintypes - 1] && !newVal.includes(null)) {
        this.deviceAdd.vaccinTypes = newVal;
      } else if (newVal.length == 0) {
        this.vaccintypesSelect = [null];
        this.deviceAdd.vaccinTypes = null;
      }
    },
    'deviceAdd.stockType'(newVal){
      this.vaccintypesFilterData = [{ value: null, text: "--All--" }]
      if(newVal == "VIDE") this.chargeVaccintypes(this.vaccintypes.filter(obj => obj.name == "BackUp"));
      else this.chargeVaccintypes(this.vaccintypes.filter(obj => obj.name != "BackUp"));
    }
    /* vaccintypes(newVal) {
      this.chargeVaccintypes(newVal);
    }, */
  },
  methods: {
    ...provincesMethods,
    retrieveProvinces(region) {
      this.provincesData = [{ value: null, text: this.$t("titles.provinces.text"), disabled: true }];
      let provinces = this.provinces;
      provinces.map((obj)=>{
        if(obj.region == region) this.provincesData.push({ value: obj.id, text: obj.name });
      })
    },
    chargeVaccintypes(vaccintypes) {
      let vData = vaccintypes || [];
      let arrayVaccins = [];
      vData.map((obj) => {
        if(obj.name != "Covid (1/2)"){
          this.vaccintypesFilterData.push({ value: obj.id, text: obj.name });
          arrayVaccins.push(obj.id);
        }
      });
      this.deviceAdd.vaccinTypes = arrayVaccins;
    },
    cancel() {
      this.clear();
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      });
      return obj;
    },
    clear() {
      this.region = null,
      this.deviceAdd = {
        serial: null,
        ref: null,
        name: null,
        marque: null,
        thermostat: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
        vaccinTypes: null,
        phones: null,
      };
      this.err = {
        serial: null,
        ref: null,
        name: null,
        marque: null,
        thermostat: null,
        type: null,
        region: null,
        province: null,
        stockType: null,
        vaccinTypes: null,
      };
    },
    add(ok) {
      if (!this.deviceAdd.serial)
        this.err.serial = this.$t("msgError.obligField.text");
      else this.err.serial = "";

      if (!this.deviceAdd.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.deviceAdd.type) this.err.type = this.$t("msgError.obligField.text");
      else this.err.type = "";

      if (!this.deviceAdd.region)
        this.err.region = this.$t("msgError.obligField.text");
      else this.err.region = "";

      if (!this.deviceAdd.province)
        this.err.province = this.$t("msgError.obligField.text");
      else this.err.province = "";

      if (!this.deviceAdd.stockType)
        this.err.stockType = this.$t("msgError.obligField.text");
      else this.err.stockType = "";

      if (!this.deviceAdd.marque)
        this.err.marque = this.$t("msgError.obligField.text");
      else this.err.marque = "";

      if (!this.deviceAdd.thermostat)
        this.err.thermostat = this.$t("msgError.obligField.text");
      else this.err.thermostat = "";

      if (!this.deviceAdd.vaccinTypes)
        this.err.vaccinTypes = this.$t("msgError.obligField.text");
      else this.err.vaccinTypes = "";

      if (
        !this.err.serial &&
        !this.err.type &&
        !this.err.name &&
        !this.err.marque &&
        !this.err.thermostat &&
        !this.err.region &&
        !this.err.province &&
        !this.err.stockType &&
        !this.err.vaccinTypes
      ) {
        let devicesData = {
          serial: this.deviceAdd.serial ? this.deviceAdd.serial : null,
          ref: this.deviceAdd.ref ? this.deviceAdd.ref : null,
          name: this.deviceAdd.name ? this.deviceAdd.name : null,
          marque: this.deviceAdd.marque ? this.deviceAdd.marque : null,
          thermostat: this.deviceAdd.thermostat ? this.deviceAdd.thermostat : null,
          type: this.deviceAdd.type ? this.deviceAdd.type : null,
          region: this.deviceAdd.region ? this.deviceAdd.region : null,
          province: this.deviceAdd.province ? this.deviceAdd.province : null,
          stockType: this.deviceAdd.stockType ? this.deviceAdd.stockType : null,
          vaccinTypes: this.deviceAdd.vaccinTypes ? this.deviceAdd.vaccinTypes : null,
          phones: this.deviceAdd.phones ? this.deviceAdd.phones : null,
        };
        let filtredDevices = this.cleanObject(devicesData);
        ok();
        this.$emit("addDevice", filtredDevices);
      }
    },
  },
};
</script>